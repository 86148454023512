import React from 'react';
import logo from '../images/logo.png';

const Sellout = () => {
  return (
    <main className="w-full bg-white text-black relative isolate">
      <span className="h-full w-full items-center justify-center inline-flex absolute inset-0 -z-10 opacity-30 [mask-image:radial-gradient(100%_100%_at_bottom_left,white,transparent)]">
        <svg id="Windframe_xwkS3VxFHj" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
          <defs>
            <pattern id="a-new-header02" patternUnits="userSpaceOnUse" width="40" height="40" patternTransform="scale(12) rotate(0)">
              <rect x="0" y="0" width="100%" height="100%" fill="hsla(0,0%,100%,1)" />
              <path d="M15 5h10v30H15zM35-5V5H5V-5zM35 35v10H5V35zM35-15h10v30H35zM55 15v10H25V15zM15 15v10h-30V15zM35 25h10v30H35zM-5 25H5v30H-5zM-5-15H5v30H-5z" strokeWidth="0.1" className="stroke-gray-900" fill="none" />
            </pattern>
          </defs>
          <rect width="800%" height="800%" transform="translate(0,0)" fill="url(#a-new-header02)" />
        </svg>
      </span>
      <section className="pt-10 md:pt-16 md:gap-y-10 items-center w-full h-screen mx-auto py-8 px-4 relative flex flex-col max-w-screen-2xl isolate overflow-y-clip gap-y-8">
        <img src={logo} alt="rustsphere logo" className="h-32 w-auto" />
        <p className="text-3xl md:text-5xl font-bold text-center">We Are Coming Soon</p>
        <p className="mx-auto text-base lg:text-lg text-center text-gray-500 max-w-2xl">
          RustSphere is the one-stop hub for all things Rust What's Coming: From game-changing mods to essential server tools, get access to plugins that elevate your experience. We're building a marketplace powered by the community. Share your creations, get feedback, and collaborate with fellow players and modders. Modders can showcase their work and earn rewards for their creations. Whether you're a server owner or player, you'll find plugins that transform your gameplay. Why RustSphere? Because we're as passionate about Rust as you are. Our mission is to empower players, modders, and server owners to push the boundaries of what's possible in Rust. This isn't just a marketplace - it's a community where innovation thrives.
        </p>
        <div className="flex gap-3">
          <button type="submit" fontfamily="Arial" className="py-3 px-6 bg-black text-white rounded-lg font-semibold w-fit">Contact us</button>
          <button type="submit" fontfamily="Arial" className="border border-2 bg-white py-3 px-6 text-gray-800 rounded-lg font-semibold w-fit">Notify me!</button>
        </div>
        <div className="mt-8 flex gap-4">
          <a id="Windframe_ueskJzHkg8" href="#" className="bg-white text-gray-800 rounded-full p-1.5 border">
            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="" id="Windframe_JdF7vpwj9W">
              <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
            </svg>
          </a>
          <a id="Windframe_52l-qinY-4" href="#" className="bg-white text-gray-800 rounded-full p-1.5 border">
            <svg
                width="64px"
                height="64px"
                viewBox="0 -28.5 256 256"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid"
                fill="#000000"
                className="h-6 w-6"
            >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                <g>
                    <path
                    d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
                    fill="#000000"
                    fillRule="nonzero"
                    />
                </g>
                </g>
            </svg>
        </a>
          <a id="Windframe_mwny1JAavy1" href="#" className="bg-white text-gray-800 rounded-full p-1.5 border">
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="" id="Windframe_gfZj-syH9ch">
              <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
            </svg>
          </a>
        </div>
      </section>
    </main>
  );
};

export default Sellout;