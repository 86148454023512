import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Register from './components/Register';  // Import the Register component
import Marketplace from './components/Marketplace';
import Homepage from './components/Homepage'; 
import Footer from './components/Footer';
import NotFoundPage from './components/NotFoundPage';
import PluginDetails from './components/PluginDetails';
import Forum from './components/Forum';
import AdminPage from './components/AdminPage';
import { CartProvider } from './components/CartContext';
import UploadPlugin from './components/UploadPlugin';
import CartPage from './components/CartPage';
import AdminPluginView from './components/AdminPluginView';
import Services from './components/Services';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('user'); 

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedRole = localStorage.getItem('role'); 

    if (token && storedRole) {
      setUserRole(storedRole); 
      setIsAuthenticated(true); 
      validateToken(token);  
    }
  }, []);

  const validateToken = async (token) => {
    try {
      const response = await fetch('/api/auth/validate-token', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setIsAuthenticated(true);
        setUserRole(data.role); 
      } else {
        handleLogout();
      }
    } catch (error) {
      handleLogout();
    }
  };

  const handleLogin = (token, role) => {
    localStorage.setItem('token', token);
    localStorage.setItem('role', role); 
    setIsAuthenticated(true);
    setUserRole(role); 
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role'); 
    setIsAuthenticated(false);
    setUserRole('user'); 
  };

  const ProtectedRoute = ({ children }) => {
    return isAuthenticated ? children : <Navigate to="/login" />;
  };

  return (
    <CartProvider>
      <Router>
        <Navbar isAuthenticated={isAuthenticated} onLogout={handleLogout} userRole={userRole} />

        <Routes>
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forum" element={<Forum />} />
        <Route path="/services" element={<Services />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/upload-plugin" element={<ProtectedRoute><UploadPlugin /></ProtectedRoute>} />
        <Route path="/cart" element={<ProtectedRoute><CartPage /></ProtectedRoute>} />
        <Route path="/plugins" element={<ProtectedRoute><Marketplace /></ProtectedRoute>} />
        <Route path="/plugins/:id" element={<ProtectedRoute><PluginDetails /></ProtectedRoute>} />
        <Route
          path="/admin"
          element={
            userRole === 'admin' ? (
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        {/* New Route for AdminPluginView */}
        <Route
          path="/admin/plugins/:id"
          element={
            userRole === 'admin' ? (
              <ProtectedRoute>
                <AdminPluginView />
              </ProtectedRoute>
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>


        <Footer />
      </Router>
    </CartProvider>
  );
}

export default App;
