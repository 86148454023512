import React, { useEffect, useState } from 'react';

function NotificationPanel() {
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch notifications if needed
  const fetchNotifications = async () => {
    try {
      const response = await fetch('/api/user/notifications', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setNotifications(data);
      } else {
        setError('Failed to fetch notifications');
      }
    } catch (error) {
      setError('Error fetching notifications');
    }
  };

  const handleBellClick = () => {
    if (!showNotifications && notifications.length === 0) {
      // Only fetch notifications if the user is logged in and the bell is clicked
      fetchNotifications();
    }
    setShowNotifications(!showNotifications);
  };

  return (
    <div className="relative">
      <button className="text-white relative hover:text-custom-orange cursor-pointer" onClick={handleBellClick}>
        <i className="fa fa-bell"></i>
        {/* Notification count if there are any */}
        {notifications.length > 0 && (
          <span className="absolute -top-2 -right-2 bg-custom-orange text-white rounded-full h-5 w-5 text-xs flex justify-center items-center">
            {notifications.length}
          </span>
        )}
      </button>

      {/* Notification dropdown (if needed) */}
      {showNotifications && notifications.length > 0 && (
        <div className="absolute right-0 mt-2 w-64 bg-white shadow-lg rounded-lg overflow-hidden">
          <ul>
            {notifications.map((notification, index) => (
              <li key={index} className="px-4 py-2 text-sm text-gray-800 border-b">
                {notification.message}
              </li>
            ))}
          </ul>
        </div>
      )}
      {showNotifications && notifications.length === 0 && (
        <div className="absolute right-0 mt-2 w-64 bg-white shadow-lg rounded-lg overflow-hidden">
          <p className="p-4 text-sm text-gray-800">No notifications</p>
        </div>
      )}

      {/* Optional error display */}
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
}

export default NotificationPanel;
